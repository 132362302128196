// Lazy load images 
document.addEventListener("DOMContentLoaded", function() {
    var lazyloadImages = document.querySelectorAll("img.lazy");    
    var lazyloadThrottleTimeout;
    
    function lazyload () {
      if(lazyloadThrottleTimeout) {
        clearTimeout(lazyloadThrottleTimeout);
      }    
      
      lazyloadThrottleTimeout = setTimeout(function() {
          var scrollTop = window.pageYOffset;
          lazyloadImages.forEach(function(img) {
              if(img.offsetTop < (window.innerHeight + scrollTop)) {
                img.src = img.dataset.src;
                img.classList.remove('lazy');
              }
          });
          if(lazyloadImages.length == 0) { 
            document.removeEventListener("scroll", lazyload);
            window.removeEventListener("resize", lazyload);
            window.removeEventListener("orientationChange", lazyload);
          }
      }, 20);
    }
    
    document.addEventListener("scroll", lazyload);
    window.addEventListener("resize", lazyload);
    window.addEventListener("orientationChange", lazyload);
});

// Input focus
$(".input-wrap input, .input-wrap textarea").focus(function () {
    $(this).parent(".input-wrap").addClass("_focused");
});
$(".input-wrap input, .input-wrap textarea").focusout(function () {
    $(this).parent(".input-wrap").removeClass("_focused");
});

// Input Dropdown
$(document).click(function () {
    $(".input-pack").removeClass("_dropdown");
});

$(".pseuinput").click(function (e) {
    e.stopPropagation();
    $(this).parents(".input-pack").toggleClass("_dropdown");
});

$('.pill-category').click(function() {
    const thisVal = $(this).val()
    const sameName = $(this).attr("name")

    const calcCatShowed = document.querySelectorAll(`.tag-category [name=${sameName}]`).length
    const popupCatPosition = $(`.modal-body .pill-category[name=${sameName}]`).index($(this))
    const popupCatPositionAjust = popupCatPosition + 2

    $(`[class^="pill-category"][name=${sameName}]`).removeClass('_selected')
    
    $(`[class^="pill-category"][name=${sameName}][value=${thisVal}]`).addClass('_selected')

    // Jika category yang dipilih dari popup tidak ada di list utama, maka category yang di highlight adalah "Kategori Lainnya"
    if (popupCatPositionAjust > calcCatShowed) {
        $(`.pill-category-other[name=${sameName}]`).addClass('_selected')
    }
})

//copy
$('._copy').click(function () {
    $(this).addClass('_copied');

    setTimeout(function () {
        $('._copy').removeClass('_copied');
    }, 900);
});

//
$('.input-pack__dropdown__list li input[type="radio"]').click(function () {
    $(this).parents('.input-pack__dropdown__list').find('li').removeClass('_active');
    $(this).parents('li').addClass('_active');

    $getSelectedName = $(this).parents('label').find('span').text();
    $(this).parents('.input-pack').find('.input-wrap .pseuinput span').text($getSelectedName);
});

// Checkbox
$('.cb input[type="checkbox"]').click(function () {
    if ($(this).is(':checked')) {
        $(this).parents('.cb').addClass('cb--checked');
    } else {
        $(this).parents('.cb').removeClass('cb--checked');
    }
});

// Radio
// $('.rb input[type="radio"]').click(function () {
//     if ($(this).is(':checked')) {
//         $(this).parents('.rb').addClass('rb--checked');
//     } else if ($('.rb input[type="radio"]').prop('checked', false)) {
//         $(this).parents('.rb').removeClass('rb--checked');
//     }
// });

//
$('input[type="file"]').change(function (e) {
    var fileName = e.target.files[0].name;
    $('.lobel').html(fileName)
});

// Currency Formatter
$("input[data-type='currency']").on({
    keyup: function () {
        formatCurrency($(this));
    },
    // blur: function () {
    //     formatCurrency($(this), "blur");
    // }
});

function formatNumber(n) {
    // format number 1000000 to 1,234,567
    return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ".")
}

function formatCurrency(input, blur) {
    // appends $ to value, validates decimal side
    // and puts cursor back in right position.

    // get input value
    var input_val = input.val();

    // don't validate empty input
    if (input_val === "") { return; }

    // original length
    var original_len = input_val.length;

    // initial caret position 
    var caret_pos = input.prop("selectionStart");

    // check for decimal
    if (input_val.indexOf(",") >= 0) {

        // get position of first decimal
        // this prevents multiple decimals from
        // being entered
        var decimal_pos = input_val.indexOf(",");

        // split number by decimal point
        var left_side = input_val.substring(0, decimal_pos);
        var right_side = input_val.substring(decimal_pos);

        // add commas to left side of number
        left_side = formatNumber(left_side);

        // validate right side
        right_side = formatNumber(right_side);

        // On blur make sure 2 numbers after decimal
        if (blur === "blur") {
            right_side += "00";
        }

        // Limit decimal to only 2 digits
        right_side = right_side.substring(0, 2);

        // join number by .
        input_val = "Rp" + left_side + "," + right_side;

    } else {
        // no decimal entered
        // add commas to number
        // remove all non-digits
        input_val = formatNumber(input_val);
        input_val = "Rp " + input_val;

        // final formatting
        // if (blur === "blur") {
        //     input_val += ".00";
        // }
    }

    // send updated string to input
    input.val(input_val);

    // put caret back in the right position
    var updated_len = input_val.length;
    caret_pos = updated_len - original_len + caret_pos;
    input[0].setSelectionRange(caret_pos, caret_pos);
}

// Gram Formatter
$("input[data-type='gram']").on({
    keyup: function () {
        formatGram($(this));
    },
    // blur: function () {
    //     formatGram($(this), "blur");
    // }
});

function formatNumber(n) {
    // format number 1000000 to 1,234,567
    return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ".")
}

function formatGram(input, blur) {
    // appends $ to value, validates decimal side
    // and puts cursor back in right position.

    // get input value
    var input_val = input.val();

    // don't validate empty input
    if (input_val === "") { return; }

    // original length
    var original_len = input_val.length;

    // initial caret position 
    var caret_pos = input.prop("selectionStart");

    // check for decimal
    if (input_val.indexOf(",") >= 0) {

        // get position of first decimal
        // this prevents multiple decimals from
        // being entered
        var decimal_pos = input_val.indexOf(",");

        // split number by decimal point
        var left_side = input_val.substring(0, decimal_pos);
        var right_side = input_val.substring(decimal_pos);

        // add commas to left side of number
        left_side = formatNumber(left_side);

        // validate right side
        right_side = formatNumber(right_side);

        // On blur make sure 2 numbers after decimal
        if (blur === "blur") {
            right_side += "00";
        }

        // Limit decimal to only 2 digits
        right_side = right_side.substring(0, 2);

        // join number by .
        input_val = left_side + "," + right_side + " Gram";

    } else {
        // no decimal entered
        // add commas to number
        // remove all non-digits
        input_val = formatNumber(input_val);
        input_val = input_val + " Gram";

        // final formatting
        // if (blur === "blur") {
        //     input_val += ".00";
        // }
    }

    // send updated string to input
    input.val(input_val);

    // put caret back in the right position
    var updated_len = input_val.length;
    caret_pos = updated_len - original_len + caret_pos;
    input[0].setSelectionRange(caret_pos, caret_pos);
}

// Barang Formatter
$("input[data-type='barang']").on({
    keyup: function () {
        formatBarang($(this));
    },
    // blur: function () {
    //     formatBarang($(this), "blur");
    // }
});

function formatNumber(n) {
    // format number 1000000 to 1,234,567
    return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ".")
}

function formatBarang(input, blur) {
    // appends $ to value, validates decimal side
    // and puts cursor back in right position.

    // get input value
    var input_val = input.val();

    // don't validate empty input
    if (input_val === "") { return; }

    // original length
    var original_len = input_val.length;

    // initial caret position 
    var caret_pos = input.prop("selectionStart");

    // check for decimal
    if (input_val.indexOf(",") >= 0) {

        // get position of first decimal
        // this prevents multiple decimals from
        // being entered
        var decimal_pos = input_val.indexOf(",");

        // split number by decimal point
        var left_side = input_val.substring(0, decimal_pos);
        var right_side = input_val.substring(decimal_pos);

        // add commas to left side of number
        left_side = formatNumber(left_side);

        // validate right side
        right_side = formatNumber(right_side);

        // On blur make sure 2 numbers after decimal
        if (blur === "blur") {
            right_side += "00";
        }

        // Limit decimal to only 2 digits
        right_side = right_side.substring(0, 2);

        // join number by .
        input_val = left_side + "," + right_side + " Barang";

    } else {
        // no decimal entered
        // add commas to number
        // remove all non-digits
        input_val = formatNumber(input_val);
        input_val = input_val + " Barang";

        // final formatting
        // if (blur === "blur") {
        //     input_val += ".00";
        // }
    }

    // send updated string to input
    input.val(input_val);

    // put caret back in the right position
    var updated_len = input_val.length;
    caret_pos = updated_len - original_len + caret_pos;
    input[0].setSelectionRange(caret_pos, caret_pos);
}

// Drawer Menu
// $('.navbar__button--drawer-btn').click(function () {
//     $('body').toggleClass('overflow-hidden');
//     $('.drawer-menu').toggleClass('_active');
//     $('.navbar__button--drawer-btn .fa').toggleClass('fa-bars');
//     $('.navbar__button--drawer-btn .fa').toggleClass('fa-times');
// });

// Profile Btn
$('#profile-btn a').click(function () {
    $('.floating-contact').addClass('d-none');
    $('#category-btn ._close').trigger('click');

    $('body').addClass('overflow-hidden');
    $('.drawer-menu').addClass('_active');
    $('.navbar-mobile__menu li').addClass('_notcurrent');
    $(this).parent().removeClass('_notcurrent');
    $(this).parent().addClass('_current');
    $(this).parent().find('._close').addClass('_focus');
});

$('#profile-btn ._close').click(function () {
    $('.floating-contact').removeClass('d-none');
    $('body').removeClass('overflow-hidden');
    $('.drawer-menu').removeClass('_active');
    $(this).parent().removeClass('_current');
    $('.navbar-mobile__menu li').removeClass('_notcurrent');
    $(this).parent().find('._close').removeClass('_focus');
});

// Category Btn
$('#category-btn a').click(function () {
    $('#profile-btn ._close').trigger('click');

    $('.category-menu').addClass('_active');
    $('.navbar-mobile__menu li').addClass('_notcurrent');
    $(this).parent().removeClass('_notcurrent');
    $(this).parent().addClass('_current');
    $(this).parent().find('._close').addClass('_focus');
});

$('#category-btn ._close').click(function () {
    $('.category-menu').removeClass('_active');
    $(this).parent().removeClass('_current');
    $('.navbar-mobile__menu li').removeClass('_notcurrent');
    $(this).parent().find('._close').removeClass('_focus');
});

//Number Separate
function numberWithCommas(number) {
    var parts = number.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return parts.join(".");
}
$(document).ready(function () {
    $(".ns").each(function () {
        var num = $(this).text();
        var commaNum = numberWithCommas(num);
        $(this).text(commaNum);
    });
});

// Navbar 
$(document).click(function() {
    $('.navbar__profile').removeClass('_active');
})


$('.navbar__profile').click(function (event) {
    event.stopPropagation();
    event.stopImmediatePropagation();
    $('.navbar__profile').not(this).removeClass('_active');
    $(this).toggleClass('_active');
});

// Navbar search
$('.navbar__search-btn').click(function () {
    $('.hide-from-chat').toggleClass('_hide');
    $('.navbar__search').toggleClass('_show');
    $('.navbar__search-btn i').toggleClass('ryd-search ryd-close');
});


//search mode
$('label._search').click(function () {
    $('.navbar').addClass('_search-mode');
    $('.navbar__links._close').css('display', 'block');
});

//loreg mode
$('label._login, label._register').click(function () {
    $('.navbar').addClass('_loreg-mode');
    $('.navbar__links._close').css('display', 'block');
    $('.loreg-wrapper').addClass('_active');
    $('body').css('overflow', 'hidden');
});

$('label._login, .loginTrigger').click(function () {
    $('#registerSection').css('display', 'none');
    $('#loginSection').css('display', 'block');
});
$('label._register, .registerTrigger').click(function () {
    $('#loginSection').css('display', 'none');
    $('#registerSection').css('display', 'block');
});

// universal close
$('.navbar__links._close button').click(function () {
    $('.navbar').removeClass('_search-mode');
    $('.navbar').removeClass('_loreg-mode');
    $('.navbar__links._close').css('display', 'none');

    //
    $('.loreg-wrapper').removeClass('_active');
    $('body').css('overflow', 'auto');
});

$('.with-loading').click(function () {
    $this = $(this);

    $this.find('b').hide();
    $this.css('opacity', '.6').prepend(`<b class="fa fa-circle-o-notch fa-spin fa-fw"></b> `);

    setTimeout(function () {
        $this.css('opacity', '1').find('b').hide();
    }, 10000);
});

// Floating WA
//copy
$('.floating-contact__btn').click(function () {
    $('.dummy-wa').toggleClass('_show');
});

$('.dummy-wa__close').click(function () {
    $('.dummy-wa').removeClass('_show');
});
