$(document).ready(function () {
    var wat = 0;
    var now = new Date();
    now.setSeconds(0);
    now.setMilliseconds(0);
    var options = {
        language: 'id',
        position: 'top right',
        autoClose: true,
        view: 'days',
        minView: 'days',
        dateFormat: 'dd/mm/yyyy',
        minDate: false, //now
        timepicker: false,
        onSelect: function (fd, d, picker) {
            if (!d) {
                console.log("No selected date!")
                return;
            }

            if (picker.minDate && d < picker.minDate && wat < 3) {
                wat++;
                console.log(fd.toString());
                console.log(d.toString());
                console.log(picker.date);
                console.log(picker.minDate);

                setTimeout(function () {
                    picker.selectDate(new Date(picker.minDate.getTime()))
                    //picker.date = new Date("December 17, 2020 03:24:00");
                    //picker.date = new Date(picker.minDate.getTime());
                });
            }
        }
    };

    $(".datepicker-input").datepicker(options);
});